import { Row, Col, Container, Navbar } from "react-bootstrap";
import HeaderLogo from "./HeaderLogo";
import HeaderNavLinks from "./HeaderNavLinks";

const HeaderNavbar = ({ currentStatus, isCaura }) => {
  return (
    <>
      {!isCaura ? <div className="bg-black header-row"></div> : ""}
      <Row className="bg-white">
        <Col>
          <Container className="p-0">
            <Navbar expand="lg" className="p-0">
              <Container className="py-2 px-0">
                <HeaderLogo isCaura={isCaura} />
                {currentStatus && (
                  <HeaderNavLinks
                    isCaura={isCaura}
                    currentStatus={currentStatus}
                  />
                )}
              </Container>
            </Navbar>
          </Container>
        </Col>
      </Row>
    </>
  );
};

export default HeaderNavbar;