import { useState } from "react";
import { Link } from "react-router-dom";
import { Accordion, Table } from "react-bootstrap";
import useAuth from "../../hooks/use-auth/useAuth";
import TilesPlusMainContentBasePage from "../basepages/tiles-plus-main-content-basepage/TilesPlusMainContentBasePage";
import HelpPageTiles from "./HelpPageTiles";
import PageHeading from "../../component/page-heading/PageHeading";


const HelpPage = () => {
  const { auth } = useAuth();
  const userRegulated = auth?.user?.regulated;
  const [isCauraUser] = useState(() => {
    if (window?.envConfig?.isCauraEnabled === "1") {
        return true;
    } else {
          return false;
        }
    });
  
  return (
    <TilesPlusMainContentBasePage
      pageTitle="Help and support"
      pageSubTitle="Find help when you need it."
      tilesContent={<HelpPageTiles userRegulated={userRegulated} />}
    >
      <PageHeading align="left">FAQs</PageHeading>
      <hr />
      <h3 className="mt-5">Finance</h3>
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Payment holidays</Accordion.Header>
          <Accordion.Body>
            <p className="mb-1">
              <strong>Repaying your payment holiday: what happens next</strong>
            </p>
            <p>We'll contact you to discuss repayment options if:</p>
            <ul>
              <li>
                Your payment holiday included your Initial Rental (the first
                payment due under your contract).
              </li>
            </ul>

            <p>
              We'll collect all other deferred payments at the end of your
              contract, which may mean that you'll continue to make payments
              after you've returned your vehicle. These payments will be spread
              over a number of months.
            </p>

            <p>
              We'll confirm your repayment schedule once your contract has
              ended.
            </p>

            <p className="pt-3 mb-1">
              <strong>
                I would like to make an early repayment. What do I need to do?
              </strong>
            </p>
            <p>
              You can make a lump sum payment or several extra payments over
              time. Find out more about making early repayments in the 'Making
              Repayments' section.
            </p>
            <p>
              Making repayments sooner will reduce the value of and/or number of
              payments you need to make after you've returned your vehicle.
            </p>

            <p className="pt-3 mb-1">
              <strong>
                I'm in informal extension. How does that affect my payment
                holiday?
              </strong>
            </p>
            <p>
              If you're in informal extension, please note that your first
              month's payment holiday repayment will coincide with your final
              extension rental invoice.
            </p>

            <p className="pt-3 mb-1">
              <strong>Making repayments: what you need to do</strong>
            </p>
            <p>
              You can make payments by bank transfer, BACs payment, or by
              setting up a regular standing order. Please use these account
              details:
            </p>
            <p className="mb-0">Bank sort code no: 30-00-00</p>
            <p>Bank Account no: 00241229</p>

            <p className="mb-0">Which payment reference do I need to use?</p>
            <p>
              Please use your Fleet Number followed by '-PH' (example 1234-PH).
            </p>
            <p>
              It's important to use the full reference (including the -PH),
              otherwise the payment will not be allocated to your deferred
              invoices and may be used towards your regular invoices.
            </p>
            <p>
              You can find your Fleet Number on the top right hand side of your
              invoice document, which you can view and download in the{" "}
              <Link to="/invoices">Manage My Vehicle</Link>.
            </p>

            <p className="pt-3 mb-1">
              <strong>Making a CHAPS payment</strong>
            </p>
            <p>
              If you're making a CHAPs payment via your bank please use the
              following account details:
            </p>
            <p className="mb-0">Bank sort code no: 30-15-99</p>
            <p>Bank Account no: 00029210</p>

            <p>
              For the payment reference please use your Fleet Number followed by
              '-PH', as above.
            </p>

            <p className="pt-3 mb-1">
              <strong>
                I'm still unable to make my monthly payments. How can you help?
              </strong>
            </p>
            <p>
              If you're unable to restart your full monthly payments, we're here
              to help (with Payment Plan or Breathing Space, for example).
            </p>
            <p>
              To help us give you the best support, complete our income and
              expenditure form online (it takes a few minutes). If you need a
              hand, contact us and we can go through the form together.
            </p>

            <p className="pt-3 mb-1">
              <strong>
                I'm experiencing financial difficulty. How can you help?
              </strong>
            </p>
            <p>
              We're here to help you through financial difficulty, whether it's
              short or long term.
            </p>
            <p>
              The first thing to do is complete our income and expenditure form
              online (it takes a few minutes), then call one of our Customer
              Priority Team on <Link to="tel:0800 015 4140">0800 015 4140</Link>
              . You'll need to quote the unique reference number we give you
              once you complete the form.
            </p>
            <p>
              We'll then go through the options together, to find you the best
              support.
            </p>
            <p>
              If you need a hand with the form, call the Customer Priority Team
              on the above number and we can go through it together.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Invoices and payments</Accordion.Header>
          <Accordion.Body>
            <p className="mb-1">
              <strong>When is my first payment due?</strong>
            </p>
            <p>
              Your first payment will be your initial rental, as detailed in
              your contract. This initial rental payment can be for either one,
              three, six, nine or twelve monthly rentals.
            </p>

            <p className="pt-3 mb-1">
              <strong>When do you collect initial rental?</strong>
            </p>
            <p>We collect the initial rental once we've delivered your car.</p>

            <p className="pt-3 mb-1">
              <strong>How do you collect payment? </strong>
            </p>
            <ol>
              <li>
                We first need to update our system to show the car as live,
                which normally happens within 48 hours of you receiving it.
              </li>
              <li>
                Once your car is live on our system, we'll raise an initial
                rental invoice.
              </li>
              <li>
                We then collect your initial rental payment via Direct Debit, 14
                days after your vehicle has been delivered.
              </li>
            </ol>

            <p className="pt-3 mb-1">
              <strong>Monthly rental payments</strong>
            </p>
            <p>
              After your initial rental payment, the monthly rental payments
              that follow are set at a fixed price for the remaining primary
              term of your contract. Monthly rental payments are subject to
              changes in VAT.
            </p>

            <p className="pt-3 mb-1">
              <strong>When are payments billed?</strong>
            </p>
            <p>
              You're billed in advance, from the date we deliver your car.
              Monthly rental payments will start once your car is a live rental
              on our system.
            </p>

            <p className="pt-3 mb-1">
              <strong>How do you collect payments?</strong>
            </p>

            <p className="mb-1">
              <strong>For vehicles via a broker </strong>
            </p>
            <p>
              For vehicles ordered and received through a broker we raise a
              monthly rental invoice, issued on the 16<sup>th</sup> of the
              month.
            </p>

            <p>
              We then collect your monthly rental payment via Direct Debit, 15
              days after the monthly rental invoice has been issued (normally
              the 1<sup>st</sup> working day of the month), unless you agreed a
              different date beforehand.
            </p>

            <p className="pt-3 mb-1">
              <strong>For vehicles from a dealer</strong>
            </p>
            <p>
              If you ordered and received your vehicle through a dealer we raise
              a monthly rental invoice, issued on the 1<sup>st</sup> of the
              month.
            </p>

            <p>
              We then collect your monthly rental payment via Direct Debit on
              the last day of the month, unless you agreed a different date
              beforehand.
            </p>

            <p className="pt-3 mb-1">
              <strong>How do I change my Direct Debit date? </strong>
            </p>
            <p>
              Once your contract has started, you're able to change your monthly
              repayment Direct Debit date. Please{" "}
              <Link to="/contact-us">contact us</Link> and we'll be happy to
              help.
            </p>

            <p className="pt-3 mb-1">
              <strong>How can I access my invoices?</strong>
            </p>
            <p>
              You can access all your invoices through Manage My Vehicle, our
              online portal. You'll be set up on Manage My Vehicle once you've
              placed your order and have either signed your contract online or
              signed a paper version (if sign online isn't available or suitable
              for you).
            </p>

            <p className="pt-3 mb-1">
              <strong>Can I receive invoices by post?</strong>
            </p>
            <p>
              Yes - please contact one of our Customer Service team, who will
              happily arrange this for you.
            </p>

            <p className="pt-3 mb-1">
              <strong>
                Can delivery date affect when payments are collected?
              </strong>
            </p>
            <p>
              As a general rule, how payments are set up will depend on whether
              you receive your car in the first or second half of the month.
            </p>
            <p>
              You should also be aware that in some cases, your delivery date
              could mean that we collect your initial rental and subsequent
              monthly rental in quick succession.
            </p>
            <p>Here are some examples to show how we collect payments:</p>

            <Table
              responsive="md"
              bordered
              className="caption-top text-color-inherit"
            >
              <caption className="text-color-inherit">
                <p>
                  <strong>
                    For cars delivered in the first half of the month (normally
                    up to the 14<sup>th</sup>):{" "}
                  </strong>
                </p>
              </caption>
              <thead>
                <tr>
                  <th style={{ width: "25%" }} scope="col">
                    Action
                  </th>
                  <th scope="col">Mid month bill example date</th>
                  <th scope="col">1st of the month bill example date</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <p>Car delivered to you</p>
                    <p>
                      (between 1<sup>st</sup> and 14<sup>th</sup> of the month)
                    </p>
                  </td>
                  <td>
                    <p>9 November</p>
                  </td>
                  <td>
                    <p>9 November</p>
                  </td>
                </tr>

                <tr>
                  <td>
                    <p>Initial rental payment taken by Direct Debit</p>
                  </td>
                  <td>
                    <p>26 November</p>
                  </td>
                  <td>
                    <p>26 November</p>
                  </td>
                </tr>

                <tr>
                  <td>
                    <p>Monthly rental taken by Direct Debit</p>
                  </td>
                  <td>
                    <p>1 December</p>
                  </td>
                  <td>
                    <p>16 December</p>
                  </td>
                </tr>

                <tr>
                  <td></td>
                  <td>
                    <p>
                      Monthly rentals will then continue to be invoiced on the
                      16<sup>th</sup> of each month and payments collected on
                      the 1<sup>st</sup> of the following month until the end of
                      your contracted term{" "}
                      <em>(unless you request a change)</em>
                    </p>
                  </td>
                  <td>
                    <p>
                      Monthly rentals will then continue to be invoiced on the 1
                      <sup>st</sup> of each month and payments collected on the
                      16th of the month until the end of your contracted term{" "}
                      <em>(unless you request a change)</em>
                    </p>
                  </td>
                </tr>
              </tbody>
            </Table>

            <Table
              responsive="md"
              bordered
              className="caption-top text-color-inherit"
            >
              <caption className="text-color-inherit">
                <p>
                  <strong>
                    For cars delivered in the second half of the month (normally
                    after the 14<sup>th</sup>):
                  </strong>
                </p>
              </caption>
              <thead>
                <tr>
                  <th style={{ width: "25%" }} scope="col">
                    Action
                  </th>
                  <th scope="col">Mid month bill example date</th>
                  <th scope="col">1st of the month bill example date</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <p>Car delivered to you</p>
                    <p>
                      (after 14<sup>th</sup> month to end of month)
                    </p>
                  </td>
                  <td>
                    <p>17 November</p>
                  </td>
                  <td>
                    <p>17 November</p>
                  </td>
                </tr>

                <tr>
                  <td>
                    <p>Initial rental payment taken by Direct Debit</p>
                  </td>
                  <td>
                    <p>4 December</p>
                  </td>
                  <td>
                    <p>4 December</p>
                  </td>
                </tr>

                <tr>
                  <td>
                    <p>Monthly rental taken by Direct Debit</p>
                  </td>
                  <td>
                    <p>
                      4 January (as this is the first working day of the month)
                    </p>
                  </td>
                  <td>
                    <p>16 December</p>
                  </td>
                </tr>

                <tr>
                  <td></td>
                  <td>
                    <p>
                      Monthly rentals will then continue to be invoiced on the
                      16<sup>th</sup> of each month and payments collected on
                      the 1<sup>st</sup> of the following month until the end of
                      your contracted term{" "}
                      <em>(unless you request a change)</em>
                    </p>
                  </td>
                  <td>
                    <p>
                      Monthly rentals will then continue to be invoiced on the
                      1st of each month and payments collected on the 16th of
                      the month until the end of your contracted term{" "}
                      <em>(unless you request a change)</em>
                    </p>
                  </td>
                </tr>
              </tbody>
            </Table>

            <p className="pt-3 mb-1">
              <strong>
                I haven't received my monthly invoice. How can I get a copy?
              </strong>
            </p>
            <p>
              All your invoices are available on the{" "}
              <Link to="/invoices">Manage My Vehicle</Link> portal, where you
              can browse, view, or download a copy (if you need one for your
              records).
            </p>

            <p className="pt-3 mb-1">
              <strong>If you prefer paper invoices</strong>
            </p>
            <p>
              If you'd prefer to have paper invoices (because you're unable to
              manage your account online, for example), please contact us so we
              can update our records and arrange this for you.
            </p>

            <p className="pt-3 mb-1">
              <strong>How do I set up a new Direct Debit?</strong>
            </p>
            <p>
              New Direct Debits can be set up online or you can contact us and
              we can go through it over the phone.
            </p>

            <p className="pt-3 mb-1">
              <strong>How do I make a payment?</strong>
            </p>
            <p>
              The easiest way to make a payment is through your internet
              banking.
            </p>
            <p>
              We'll need your vehicle registration number or Lex account number
              as the reference, so we can match the payment to the correct
              account.
            </p>
            <p>
              Please make sure you enter the correct bank account details,
              because we cannot be held responsible for any incorrect details.
            </p>

            <Table
              responsive="md"
              bordered
              className="caption-top text-color-inherit"
            >
              <caption className="text-color-inherit">
                <p>
                  We're currently developing the Manage My Vehicle portal to
                  enable online payments.
                </p>
              </caption>
              <thead>
                <tr>
                  <th>Bank Transfer</th>
                  <th>Cheques</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <p>Sort Code: 30-00-00</p>
                    <p>Account: 00241229</p>
                  </td>
                  <td>
                    <p>(Made payable to Lex Autolease Ltd)</p>
                  </td>
                </tr>

                <tr>
                  <td>
                    <p>Account name: Lex Autolease Ltd</p>
                  </td>
                  <td>
                    <p>Cashiers</p>
                    <p>Lex Autolease Limited</p>
                    <p>Heathside Park</p>
                    <p>Heathside Park Road</p>
                    <p>Stockport, SK3 0RB</p>
                  </td>
                </tr>

                <tr>
                  <td>
                    <p>Ref: Your fleet number or registration number</p>
                  </td>
                  <td>
                    <p>
                      Write your name, account number or registration number on
                      the back.
                    </p>
                  </td>
                </tr>
              </tbody>
            </Table>

            <p className="pt-3 mb-1">
              <strong>Can I pay over the phone?</strong>
            </p>
            <p>
              We're currently unable to process payments over the phone, so
              please arrange payment via your bank using the above details.
            </p>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <h3 className="mt-5">Looking after your vehicle</h3>
      <Accordion>
        <Accordion.Item eventKey="2">
          <Accordion.Header>Tyres</Accordion.Header>
          <Accordion.Body>
            <p className="mb-1">
              <strong>I need a new tyre. What do I do?</strong>
            </p>

            <p className="pt-3 mb-1">
              <strong>I have maintenance cover</strong>
            </p>
            <p>
              If you have maintenance cover, we authorise changing tyres at a
              maximum 2mm tread depth.
            </p>
            <p>
              The legally required minimum tread depth is 1.6mm, you must not
              let your tyres go below this.
            </p>

            <p className="pt-3 mb-1">
              <strong>Our preferred tyre supplier </strong>
            </p>
            <p>
              Our preferred tyre supplier is Kwik Fit, the UK's largest tyre
              fitter with more than 600 locations and around 100 mobile fitters.
            </p>

            <p>
              To arrange tyre replacement please contact your nearest Kwik Fit
              or{" "}
              <a
                href="https://driver.lexautolease.co.uk"
                target="_blank"
                rel="noopener noreferrer"
              >
                book online
              </a>{" "}
              and follow the tyre repair and replacement option.
            </p>

            <p className="pt-3 mb-1">
              <strong>I don't have maintenance cover</strong>
            </p>
            <p>
              If you don't have maintenance cover you can still use Kwik Fit,
              either by making an appointment by phone or driving to a Kwik Fit
              centre. You're liable for any work completed, payable directly to
              Kwik Fit.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>Glass</Accordion.Header>
          <Accordion.Body>
            <p className="mb-1">
              <strong>
                My windscreen is damaged and I can't drive my vehicle. What do I
                do?
              </strong>
            </p>

            <p>
              Glass repairs and replacements are covered by most fully
              comprehensive motor insurance policies, usually with a small
              excess. Check your policy before making any arrangements.
            </p>

            <p>
              Alternatively, you can contact any glass provider who will bill
              you directly for any repairs or replacements.
            </p>

            <p>
              It's your responsibility to make sure the vehicle is roadworthy.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>Service and maintenance advice</Accordion.Header>
          <Accordion.Body>
            <p className="mb-1">
              <strong>How do I know if I have maintenance cover?</strong>
            </p>
            <p>
              If you have maintenance cover, you'll find it detailed in your
              contract document. If you're unsure whether you have it or not,
              contact us.
            </p>
            <p>
              Maintenance cover is optional and included at the start of your
              contract with us, as an additional cost that's part of your
              monthly rental payment.
            </p>
            <p>
              We can't add maintenance cover once your contract (lease) has
              started.
            </p>
            <p className="pt-3 mb-1">
              <strong>What's included in maintenance cover?</strong>
            </p>
            <ul>
              <li>Routine servicing.</li>
              <li>Tyres.</li>
              <li>Replacement parts.</li>
              <li>Repair.</li>
              <li>Associated labour costs due to fair wear and tear.</li>
            </ul>
            {!isCauraUser ? (<p> 
              The vehicle must be serviced by an approved service dealer,
              following the manufacturer's guidelines. To find your local dealer please visit driver.lexautolease.co.uk.
            </p>
            ) : (
              <>
            <p>
              The vehicle must be serviced by an approved service dealer,
              following the manufacturer's guidelines. You can find your nearest dealer and book services and repairs in the <Link to="/promo-caura">Caura app.</Link>
            </p>
             <p>For servicing, routine maintenance or repairs, please contact your local franchised dealer directly, or book through the <Link to="/promo-caura">Caura app.</Link>
             </p> 
             </>
            )}
            <p className="pt-3 mb-1">
              <strong>Not included in maintenance cover</strong>
            </p>
            <ul>
              <li>
                Day-to-day consumables such as fuel, oil, anti-freeze, engine
                and battery fluids, and AdBlue.
              </li>
              <li>Sat-nav updates.</li>
              <li>
                Repairs as a result of driver misuse, abuse, or accident
                negligence.
              </li>
              <li>Items damaged, broken, stolen or vandalised.</li>
              <li>
                Failure occurring through driver-negligence or non-maintenance.
              </li>
            </ul>
            <p className="pt-3 mb-1">
              <strong>
                A warning light has come on in my car. What do I do?
              </strong>
            </p>
            <p>
              Please contact your local franchised dealer and give them the
              details. They'll be able to give you an appointment based on what
              you need.
            </p>
            <p className="pt-3 mb-1">
              <strong>How do I know when my vehicle is due a service?</strong>
            </p>
            <p>Please refer to your vehicle's handbook.</p>
            <p>
              Service intervals vary from manufacturer to manufacturer and are
              generally based on age and mileage. Many vehicles have a warning
              light on the dashboard to let you know a service is due.
            </p>
            <p>
              To maintain your warranty, it's essential to service your vehicle
              in line with the manufacturer's guidelines. Not doing so could
              incur extra costs for damage caused by missing a service.
            </p>
            <p className="pt-3 mb-1">
              <strong>
                My vehicle needs a service and/or maintenance. How do I arrange
                this?{" "}
              </strong>
            </p>
            <p>
              For servicing and routine maintenance or repair, please contact
              your local franchised dealer directly. They will be able to
              arrange an appointment based on what you need.
            </p>
            <p className="pt-3 mb-1">
              <strong>Vehicle collection and delivery</strong>
            </p>
            <p>
              Many dealers provide a collection and delivery service (depending
              on availability). Please speak to your franchised dealer when you
              call to make a booking, or alternatively ask about while-you-wait
              appointments.
            </p>
            <p className="pt-3 mb-1">
              <strong>What's excluded from my maintenance contract?</strong>
            </p>
            <p>
              You're liable for any repairs resulting from damage, theft,
              vandalism, driver neglect, or misuse. We'll send you an invoice
              for these repairs and take payment via Direct Debit.
            </p>
            <p className="pt-3 mb-1">
              <strong>
                I'm not sure if I have maintenance cover. How do I find out?
              </strong>
            </p>
            <p>
              Check your contract to see if you included maintenance cover when
              you took out the contract with us. If you're unsure,{" "}
              <Link to="/contact-us">contact us</Link>.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="5">
          <Accordion.Header>Breakdown</Accordion.Header>
          <Accordion.Body>
            <p className="mb-1">
              <strong>My car has broken down. What do I do?</strong>
            </p>

            <p>
              The priority is to repair your vehicle so you can continue your
              journey with minimum disruption.
            </p>

            <p className="pt-3 mb-1">
              <strong>If the vehicle is within warranty</strong>
            </p>
            <p>
              You can arrange for our breakdown provider to attend your vehicle
              by contacting us.
            </p>

            <p>
              During the initial warranty period any breakdowns are referred to
              the vehicle manufacturer's preferred breakdown provider.
            </p>
            <p>
              The level of cover depends on the manufacturer. If the
              manufacturer's level of cover is of the same standard available
              from Lex Autolease, our recovery agent may ask the manufacturer to
              respond to your breakdown.
            </p>

            <p>
              If your manufacturer's cover has expired or does not provide
              benefits like the Lex Autolease scheme, our recovery agent will
              respond.
            </p>

            <p>
              If your vehicle cannot be repaired at the roadside, it will be
              recovered to the nearest Lex Autolease approved supplier who can
              attend to the repairs. You're responsible for collecting the
              vehicle once it's repaired.
            </p>

            <p className="pt-3 mb-1">
              <strong>
                If the vehicle is outside warranty but has maintenance cover
                which includes breakdown cover
              </strong>
            </p>

            <p>
              If your vehicle is outside the warranty period and you have
              maintenance cover which includes breakdown cover. You can arrange
              for our breakdown provider to attend your vehicle by contacting
              us.
            </p>

            <p className="pt-3 mb-1">
              <strong>
                If the vehicle is outside warranty but has no maintenance cover
              </strong>
            </p>

            <p>
              If your vehicle is outside the warranty period but has no
              maintenance cover, you can call our breakdown cover provider.
              Please be aware that you are liable for any charges, which you'd
              need to pay directly to them.
            </p>

            <p className="pt-3 mb-1">
              <strong>If you don't have breakdown cover</strong>
            </p>
            <p>
              If you don't have breakdown cover privately or as part of your
              lease contract your vehicle may have breakdown cover provided by
              the manufacturer. Please refer to your manufacturer handbook and
              warranty.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="6">
          <Accordion.Header>Travelling abroad</Accordion.Header>
          <Accordion.Body>
            <p className="mb-1">
              <strong>Can I drive my lease car abroad?</strong>
            </p>

            <p>
              Yes. Before arranging your trip please go to Lex Autolease Driver
              Portal - European travel documents. If you're unable to complete
              this online, contact us and we'll be happy to help.
            </p>

            <p>
              To make sure all documentation is in place for your trip, your
              travel date must be at least 7 days away.
            </p>

            <p>
              You can find further information on other documentation you may
              need for individual countries{" "}
              <a
                href="https://www.gov.uk/driving-abroad"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>
              .
            </p>

            <p className="pt-3 mb-1">
              <strong>What happens if I break down when I'm abroad?</strong>
            </p>
            <p className="mb-1">
              <strong>If you have maintenance cover</strong>
            </p>
            <p>
              Please contact us, selecting the appropriate option. European
              Breakdown Service is part of our maintenance cover (subject to
              conditions, which we'll go through when you arrange your trip).
            </p>

            <p className="pt-3 mb-1">
              <strong>If you don't have maintenance cover</strong>
            </p>

            <p>
              You're responsible for the cost of any repairs and/or to get the
              car back to the UK. Please check the details of any breakdown
              policy you have, to find out what you'll be covered for.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="7">
          <Accordion.Header>MOT</Accordion.Header>
          <Accordion.Body>
            <p className="mb-1">
              <strong>How do I find out the date of my MOT?</strong>
            </p>

            <p>
              You'll receive automatic reminders in the run-up to your vehicle's
              MOT due date. If your vehicle is pre-registered, the date of your
              MOT may not be 3 years from the delivery of your vehicle (4 years
              if Northern Ireland registered).&nbsp; You can check the date by
              visiting the DVLA website{" "}
              <a
                href="https://eur02.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.gov.uk%2Fcheck-mot-status%3F&amp;data=04%7C01%7CSarah.Warburton%40lexautolease.co.uk%7C644fba0c0257461b564408d9a2d1c443%7C3ded2960214a46ff8cf4611f125e2398%7C0%7C0%7C637719844090893552%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C1000&amp;sdata=Zo8zf5TwU3huk2dZmSLArdH%2FkyKuPvjA9A7D9kKzgfA%3D&amp;reserved=0"
                target="_blank"
                rel="noopener noreferrer"
              >
                Check the MOT status of a vehicle - GOV.UK (www.gov.uk)
              </a>
              .
            </p>

            <ul>
              <li>1st reminder: approx. 56 days before MOT expires.</li>
              <li>2nd reminder: approx. 30 days before MOT expires.</li>
              <li>3rd reminder: approx. 7 days before MOT expires.</li>
            </ul>

            <p>
              It's your responsibility to make sure the vehicle has a valid MOT
              and is safe, legal and roadworthy.
            </p>

            <p className="pt-3 mb-1">
              <strong>
                How do I book an MOT and what do I need to do before it?
              </strong>
            </p>

            <p className="mb-1">
              <strong>If you have maintenance cover</strong>
            </p>

            <p>
              Please contact your local Kwik Fit centre or local dealer to book
              an appointment. Before you book your MOT, please remember to check
              all lights, the condition of your tyres, and your windscreen for
              chips and cracks.
            </p>

            <p className="pt-3 mb-1">
              <strong>If you have don't have maintenance cover</strong>
            </p>

            <p>
              You'll need to arrange for the MOT to be completed at an
              authorised test centre. You're liable for the cost of the MOT and
              any other work required for the vehicle to pass the MOT, payable
              directly to the garage on completion of the work and before you
              collect the vehicle.
            </p>

            <p className="pt-3 mb-1">
              <strong>What happens if my vehicle fails its MOT?</strong>
            </p>

            <p>
              Most vehicles fail an MOT due to tyre wear or windscreen damage.
              To avoid MOT failure make the appropriate checks and rectify any
              problems before the test.
            </p>

            <p className="pt-3 mb-1">
              <strong>If you have maintenance cover</strong>
            </p>

            <p>
              If you have maintenance cover included in your contract, Lex
              Autolease will authorise any repairs with the garage.
            </p>
            <p className="pt-3 mb-1">
              <strong>If you have don't have maintenance cover</strong>
            </p>

            <p>
              If you do not have maintenance cover included in your contract,
              you will be liable for the cost of any repairs required for an MOT
              pass.
            </p>

            <p className="pt-3 mb-1">
              <strong>
                If the vehicle needs an MOT before the end of the contract
              </strong>
            </p>

            <p>
              It's your responsibility to make sure your vehicle has a valid MOT
              at the end of its contract. Not doing so could incur additional
              charges if we're unable to collect your vehicle with a valid MOT.
            </p>

            <p className="pt-3 mb-1">
              <strong>What is AdBlue&reg;?</strong>
            </p>
            <p>
              AdBlue&reg; is a diesel exhaust fluid that's introduced into the
              exhaust gases using an exhaust treatment system called selective
              catalytic reduction (SCR). Up to 90% of the nitrogen oxides (NOx)
              are converted into their environmentally friendly components:
              nitrogen and water.
            </p>

            <p>
              You can check if your vehicle has an SCR system by referring to
              your vehicle handbook or directly with the manufacturer.
            </p>

            <p>
              If your vehicle needs topping up with AdBlue&reg; there should be
              an electronic indicator on the dashboard. It should be topped up
              at your regular servicing interval or as and when required,
              according to manufacturer guidelines.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="8">
          <Accordion.Header>
            Fleet administration, fines and penalties
          </Accordion.Header>
          <Accordion.Body>
            <p className="mb-1">
              <strong>What happens if I get a fine or penalty?</strong>
            </p>

            <p className="mb-1">
              <strong>Paying the fine</strong>
            </p>

            <p>
              As the owner of the vehicle, fine and penalty notices are sent
              directly to us. We'll forward them on to you because you're
              responsible for payment.
            </p>

            <p>
              Alternatively, we may pay the fine and ask you to reimburse us. We
              will charge an admin fee of £10.
            </p>

            <p className="pt-3 mb-1">
              <strong>Failing to pay the fine</strong>
            </p>

            <p>
              If you fail to pay the fine and the issuing authority sends it to
              us for payment, we will charge a further £25 admin fee. Full
              details of charges are included in your hire contract.
            </p>

            <p className="pt-3 mb-1">
              <strong>I want to challenge a fine. What do I need to do?</strong>
            </p>

            <p>
              If you receive a parking fine and want to challenge it, you'll
              need to take it up with the issuing authority.
            </p>

            <p>
              You have the right to appeal the fine regardless of whether Lex
              Autolease has already paid it or provided your details to the
              issuer.
            </p>

            <p>
              Details of where and how to appeal are usually provided on the
              notice. Alternatively, you can contact the issuing authority to
              confirm their appeals process.
            </p>

            <p className="pt-3 mb-1">
              <strong>
                I'm driving into the London Congestion Zone. What do I need to
                do?
              </strong>
            </p>

            <p className="mb-1">
              <strong>Pre-register your trip</strong>
            </p>
            <p>
              You need to pay for your trip into the London Congestion Zone,
              either pre-registering and paying or making the payment on the day
              of your trip.
            </p>

            <p>
              You can register and pay on the{" "}
              <a
                href="https://tfl.gov.uk/modes/driving/congestion-charge"
                target="_blank"
                rel="noopener noreferrer"
              >
                Transport for London
              </a>{" "}
              website.
            </p>

            <p className="pt-3 mb-1">
              <strong>What happens if you don't pre-register your trip</strong>
            </p>

            <p>
              If your vehicle isn't pre-registered or you don't pay on the day,
              Transport for London will issue a Penalty Charge Notice to Lex
              Autolease (because we're the vehicle's registered keeper).
            </p>

            <p>
              London by-law states that Congestion Charge notices must be paid
              by the registered keeper, so we will pay the fine and charge the
              full amount back to you. You may also be charged an administration
              fee of £25.
            </p>

            <p>
              If you wish to appeal the charge, our payment does not affect your
              rights, and you can appeal directly to{" "}
              <a
                href="https://tfl.gov.uk/modes/driving/congestion-charge/penalties-and-enforcement/challenge-a-penalty-charge"
                target="_blank"
                rel="noopener noreferrer"
              >
                Transport for London
              </a>
              .
            </p>

            <p className="pt-3 mb-1">
              <strong>I need a parking permit. How do I get one?</strong>
            </p>

            <p>
              <Link to="/contact-us">Contact us</Link> and we can provide you
              with the documentation you need to request a permit from your
              local authority.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="9">
          <Accordion.Header>Accidents</Accordion.Header>
          <Accordion.Body>
            <p className="mb-1">
              <strong>I've had an accident. What do I need to do?</strong>
            </p>

            <p>
              Start by reporting the accident to your insurance company, then
              contact us (as the owner of the vehicle) so we can log it on our
              records.
            </p>

            <p className="pt-3 mb-1">
              <strong>If the vehicle needs repairing</strong>
            </p>

            <p>
              Any repairs must be completed to a high standard. If not, they are
              likely to be charged as end-of-contract damage if we have to
              rectify below-standard repairs before the vehicle goes to auction.
            </p>

            <p className="pt-3 mb-1">
              <strong>
                My insurer has declared my vehicle a write-off. What happens
                next?
              </strong>
            </p>

            <p>
              Once the settlement has been agreed any additional rental payments
              will be refunded, but you do need to continue with your regular
              monthly rental payments until the finance on the vehicle has been
              settled.
            </p>

            <p>
              If the settlement value is less than the finance outstanding on
              the vehicle, we will invoice you with this amount for payment.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="10">
          <Accordion.Header>
            End of contract options and additional charges
          </Accordion.Header>
          <Accordion.Body>
            <p className="mb-1">
              <strong>
                I'm reaching the end of my contract and want to return my
                vehicle. What do I need to do?
              </strong>
            </p>
            <p>
              You can arrange to return your vehicle through{" "}
              <Link to="/vehicle-collection">Manage My Vehicle</Link>.
              Collection can be booked up to 90 days before your contract
              ends,but please be aware that we can't collect your vehicle before
              then because it would require an early termination of your
              contract.
            </p>

            <p className="pt-3 mb-1">
              <strong>
                If you're unable to arrange collection on Manage My Vehicle
              </strong>
            </p>
            <p>
              Please <Link to="/contact-us">contact us</Link> and we will be
              happy to help.
            </p>
            <p className="pt-3 mb-1">
              <strong>Collection checklist</strong>
            </p>
            <p>On collection your vehicle needs to be as follows:</p>
            <ul>
              <li>
                Roadworthy. A vehicle may not be roadworthy if, for example,
                your tyres don't have legal tread depth or the windscreen is
                cracked.
              </li>
              <li>Complete with a valid MOT.</li>
              <li>Clean enough for inspection.</li>
              <li>
                With a minimum quarter of a tank of fuel or fully charged.
              </li>
            </ul>
            <p className="pt-3 mb-1">
              <strong>End of contract charges</strong>
            </p>
            <p>You'll need to pay any charges that are due for:</p>
            <ul>
              <li>
                Excess mileage, beyond your agreed mileage allowance. Your
                mileage allowance and excess mileage rate are detailed in your
                contract.
              </li>
              <li>Damage to the vehicle outside of fair wear and tear.</li>
              <li>
                Missing spare key, service book, or any accessories that were
                supplied with the vehicle.
              </li>
            </ul>
            <p>
              Once we've collected your vehicle, we'll be in touch if any
              charges are due.
            </p>
            <p className="pt-3 mb-1">
              <strong>
                I'm reaching the end of my contract and want to keep using my
                vehicle. What do I need to do?
              </strong>
            </p>
            <p>
              To keep using the vehicle after your contract has ended, you have
              two options.
            </p>
            <p className="pt-3 mb-1">
              <strong>Option 1: Formal extension period</strong>
            </p>
            <p>
              You can apply to formally extend your contract, as long as the
              full contract term from the original start date (including the
              extension) does not exceed 60 months. Your application is subject
              to status, a credit application and affordability check.
            </p>
            <p>
              You may also be able to request an amendment to your contracted
              annual mileage, as long as you have more than 6 months left on
              your contract - and please be aware your monthly rental payments
              may change.
            </p>
            <p>
              For more information or to apply for a formal extension please go
              to <Link to="/contract-amendment">Manage My Vehicle</Link>, where
              you can complete the application form, get an amended quote and
              accept online.
            </p>
            <p className="pt-3 mb-1">
              <strong>Option 2: Informal extension period</strong>
            </p>
            <p>
              If you don't arrange to return your vehicle or apply for a formal
              extension period, your contract will automatically enter into an
              informal extension period. You don't need to contact us if you
              choose this option.
            </p>
            <p className="pt-3 mb-1">
              <strong>Informal extension period: what you need to know</strong>
            </p>
            <ul>
              <li>
                You'll carry on paying monthly, but these payments may be more
                than what you're paying now.
              </li>
              <li>
                Payments will move from an 'in advance' status to an 'in
                arrears' status. Please refer to your contract and the section
                'Rentals payable after the Minimum Period' to find out what you
                will pay (or check your terms and conditions).
              </li>
              <li>The contract terms and conditions will continue to apply.</li>
              <li>
                To end your contract at a later date when you no longer wish to
                use the vehicle, contact us.
              </li>
              <li>
                Your first month's payment holiday repayment will coincide with
                your final extension rental invoice.
              </li>
            </ul>
            <p className="pt-3 mb-1">
              <strong>
                When I return my car, what condition should it be in?
              </strong>
            </p>
            <p>
              When you return your car, we expect it to be in good condition. We
              allow for fair wear and tear from normal and reasonable driving.
            </p>
            <p>
              We subscribe to the British Vehicle Rental and Leasing Association
              (BVRLA) code of practice and the industry fair wear and tear
              standard which you can see{" "}
              <a
                href="https://driver.lexautolease.co.uk/Login/WearAndTearGuide"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>
              .
            </p>

            <p className="pt-3 mb-1">
              <strong>If the car is damaged</strong>
            </p>

            <p>
              Fair wear and tear shouldn't be confused with damage. Damage can
              be caused by impact, inappropriate stowing of items, harsh
              treatment, negligent acts, or failure to service the vehicle
              according to the manufacturer's recommendations.
            </p>

            <p>
              The collection driver will inspect your car and note any damage,
              before we carry out a full inspection once the car's been returned
              to us. We'll record any additional damage that falls outside of
              the{" "}
              <a
                href="https://driver.lexautolease.co.uk/Login/WearAndTearGuide"
                target="_blank"
                rel="noopener noreferrer"
              >
                BVRLA Fair Wear and Tear guidelines
              </a>
              , and may charge you a fee for any damage.
            </p>

            <p className="pt-3 mb-1">
              <strong>What you need to do</strong>
            </p>

            <p>
              If the car is damaged you may prefer to arrange your own repairs
              before we collect the car, as it may be more economical that way.
              Any repairs must be to a high standard.
            </p>

            <p className="pt-3 mb-1">
              <strong>How do End of Contract charges work?</strong>
            </p>

            <p>
              After we've inspected your vehicle, and if any damage falls
              outside fair wear and tear standards, we'll send you an invoice.
            </p>

            <p>
              If you have exceeded the agreed contract mileage, you may have to
              pay excess mileage charges (outlined in your original contract).
            </p>

            <p className="pt-3 mb-1">
              <strong>Excess mileage example 1</strong>
            </p>

            <p>
              You choose an annual mileage of 10,000 miles over 48 months (4
              years). Your total contracted mileage is 40,100 miles, which
              includes the allowance of 100 miles for delivering your vehicle.
            </p>

            <p className="mb-0">If you do the following mileage:</p>
            <p className="mb-0">Year 1: 12,000 miles</p>
            <p className="mb-0">Year 2: 11,000 miles</p>
            <p className="mb-0">Year 3: 9,000 miles</p>
            <p className="mb-0">Year 4: 7,000 miles</p>

            <p>Total mileage over the contract: 39,100 miles.</p>

            <p>
              In this example you wouldn't be charged excess mileage, because
              you have not exceeded your total contracted mileage of 40,100
              miles, despite exceeding your annual mileage in years 1 and 2.
            </p>

            <p className="mb-0">If you do the following mileage:</p>
            <p className="mb-0">Year 1: 9,000 miles</p>
            <p className="mb-0">Year 2: 10,000 miles</p>
            <p className="mb-0">Year 3: 11,000 miles</p>
            <p className="mb-0">Year 4: 12,000 miles.</p>

            <p>Total mileage over the contract: 42,100 miles.</p>

            <p>
              In this example you would be charged excess mileage, because you
              exceeded your total contracted mileage of 40,100 miles, even
              though you didn't exceed your annual mileage in years 1 and 2.
            </p>

            <p className="pt-3 mb-1">
              <strong>Excess mileage example 2</strong>
            </p>

            <p>
              If the excess stated in your contract is 5p per mile, and you
              exceeded your mileage allowance by 2,000 miles, you would be
              charged £100.
            </p>

            <p>
              Any excess mileage and/ or damage charges will be invoiced
              separately after the disposal of your car.
            </p>

            <p>
              Please note: you will not receive a refund if you travel less than
              the total contracted mileage rate over the term of the contract.
            </p>

            <p className="pt-3 mb-1">
              <strong>For informal extensions</strong>
            </p>

            <p>
              If you choose to keep your vehicle after the initial contract end
              date you'll enter into an informal extension period. Your mileage
              will be prorated, so if go over your allowed mileage you will be
              charged excess mileage at the amount agreed in your contract.
            </p>

            <p className="pt-3 mb-1">
              <strong>Informal extension example</strong>
            </p>

            <p>
              If your contracted annual mileage is 12,000 miles per year over a
              3-year period and you keep the vehicle for 2 months longer than
              your original contract end date, you can return the vehicle with
              2,000 additional miles.
            </p>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <h3 className="mt-5">Your contract</h3>
      <Accordion>
        <Accordion.Item eventKey="11">
          <Accordion.Header>
            Can I make changes to my personal contract?
          </Accordion.Header>
          <Accordion.Body>
            <p>
              We want to make sure that your contract is always the right fit
              for you, especially if your circumstances or driving habits
              change.
            </p>
            <p>
              When you take out a lease with us, you agree to a length of
              contract and a mileage allowance. They should suit your needs as
              much as possible, so you may be able to ask to change them.
            </p>
            <ul>
              <li>
                If you're <u>driving more miles than expected</u> - it's usually
                best to increase your mileage allowance.
              </li>
              <li>
                If you're <u>driving fewer miles than expected</u> - it's better
                to reduce your mileage allowance.
              </li>
            </ul>
            <p>
              It's a good idea to get a quote before asking to make any changes
              to your contract, so you can weigh up your options. That way, you
              can find out how any changes might affect your monthly rental
              costs.
            </p>
            <p>
              You can also ask for a quote over the phone. Call us on{" "}
              <Link to="tel:0344 879 6633">0344 879 6633</Link>, Monday - Friday
              between 9am - 5pm. We're closed on weekends and bank holidays.
            </p>
            <p>
              Before we make any changes to your contract, we'll carry out a
              credit check. This might affect your credit file, so we'll always
              ask your permission first. Your payment date and method will
              always stay the same, even if you make changes.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="12">
          <Accordion.Header>
            What can I do if I go over my mileage allowance?
          </Accordion.Header>
          <Accordion.Body>
            <p>
              If you're over your agreed allowance when you return your vehicle,
              we'll ask you to pay an excess mileage fee.
            </p>
            <p>
              You may be able to avoid this fee by extending your contract. You
              can also request to change your mileage allowance if you have at
              least three monthly payments left to make on your current
              contract.
            </p>
            <p>
              It's worth remembering that we'll need to carry out a credit check
              before we make any changes to your contract. We'll always ask your
              permission first.
            </p>
            <p>
              We don't refund you for any unused mileage, so it's best to make
              changes to your allowance as soon as you can.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="13">
          <Accordion.Header>How do I extend my contract?</Accordion.Header>
          <Accordion.Body>
            <p>There are two ways to extend your contract:</p>
            <ul>
              <li>
                <strong>Ask us to extend your lease</strong>
              </li>
              <p>
                You may be able to extend your term up to a maximum of 60 months
                from the vehicle's registration date. We call this a{" "}
                <strong>formal extension</strong>.
              </p>
              <li>
                <strong>Do nothing when your agreed lease term ends</strong>
              </li>
              <p>
                If you'd like to keep your vehicle longer than agreed without
                extending your contract, you'll automatically move onto an{" "}
                <strong>informal extension.</strong>
              </p>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="14">
          <Accordion.Header>What's a formal extension?</Accordion.Header>
          <Accordion.Body>
            <p>
              A <strong>formal extension</strong> is when you ask us to extend
              your lease agreement. You can extend up to a maximum of 60 months
              from the date the vehicle is registered.
            </p>
            <p>
              So, if your current contract is for 36 months, you can ask to add
              up to another 24 months to your term. If you'd like to keep your
              vehicle for longer, you'll automatically switch to an{" "}
              <strong>informal extension</strong> after your maximum 60 months
              have passed.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="15">
          <Accordion.Header> What's an informal extension?</Accordion.Header>
          <Accordion.Body>
            <p>
              An informal extension is when you keep your vehicle longer than
              planned, without a formal agreement. This gives you the
              flexibility to return your vehicle when you like. You won't have a
              new contract, so there will be no early termination fees to pay.
            </p>
            <p>
              If you choose to keep your vehicle for longer, your mileage
              allowance will continue in the same way as agreed in your original
              contract. For example, if your original contract term was 36
              months with a 10,000 yearly mileage allowance, your total mileage
              allowance would be 30,000 miles. If you decide to keep the vehicle
              for an extra year, your new total mileage allowance would be
              40,000.
            </p>
            <p>
              This is usually better as a short-term solution. If you're in an
              informal extension for over a year, please get in touch so we can
              discuss your options.
            </p>
            <p>
              If you have a maintenance package as part of your existing
              contract, it's important to note that if your vehicle is damaged
              or faulty and we think that the cost of repair is uneconomical, we
              reserve the right to keep the vehicle.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="16">
          <Accordion.Header>
            Do my monthly payments stay the same in an informal extension?
          </Accordion.Header>
          <Accordion.Body>
            <p>
              When you go into an informal extension, the amount you pay each
              month may change. We work out your new amount by calculating your
              average monthly payment over the full term of your original lease.
            </p>
            <p>
              This average includes any payments greater than your normal
              monthly amount made at the start of your contract, so your new
              payment amount may increase. It may be cheaper to extend your
              contract formally if you plan to keep your vehicle for a while, or
              if you change your mileage allowance.
            </p>
            <p>
              You'll find how much your informal extension payments will be in
              your agreement.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="17">
          <Accordion.Header>
            Do my monthly payment dates stay the same in an informal extension?
          </Accordion.Header>
          <Accordion.Body>
            <p>
              When you lease one of our vehicles, you make your payments before
              each monthly rental period. We ask you which day of the month
              you'd like to make your Direct Debit payments. If you don't have a
              preferred date, we set one for you, usually at the beginning or in
              the middle of the month.
            </p>
            <p>
              If you enter an informal extension, we change the way we invoice
              you. We send your invoice at the end of each monthly rental
              period, so your payment date could change.
            </p>
            <p>
              You can find out more about how an informal extension might affect
              your monthly payment dates in Manage My Vehicle.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="18">
          <Accordion.Header>
            How can I formally extend my contract?
          </Accordion.Header>
          <Accordion.Body>
            <p>
              If you ask to extend your contract, we'll carry out a credit check
              to make sure it's still affordable. If your credit check isn't
              successful, you can choose to enter an informal extension or
              return your vehicle. We'll always ask if you agree to the credit
              check.
            </p>
            <p>There are two ways to ask to extend your contract formally:</p>
            <ul>
              <li>
                <strong>Extend your contract online</strong>
              </li>
              <p>
                The quickest way to apply for a formal extension is online
                through <Link to="/">Manage My Vehicle</Link>.
              </p>
              <li>
                <strong>Extend your contract over the phone.</strong>
              </li>
              <p>
                Call us on <Link to="tel:0344 879 6633">0344 879 6633</Link>{" "}
                Monday - Friday between 9am - 5pm. We're closed on weekends and
                bank holidays.
              </p>
            </ul>
            <p>
              We'll usually email your contract to you on the same day. If
              you're happy with everything, you'll need to print and sign the
              documents and{" "}
              <Link to="mailto: customer.services@lexautolease.co.uk">
                return them by email
              </Link>
              .
            </p>
            <p>
              If you're not able to scan or email the documents, please post
              them to:
            </p>
            <p>FAO Broker / Branded Team</p>
            <p>Heathside Park</p>
            <p>Heathside Park Road</p>
            <p>Stockport, SK3 0RB</p>

            <p>
              <strong>
                Please send documents to this address only. Using any other
                postal address shown on your documents can cause a delay in your
                application.
              </strong>
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="19">
          <Accordion.Header>What's an excess mileage fee?</Accordion.Header>
          <Accordion.Body>
            <p>
              When you lease a vehicle with us, you agree to a set mileage
              allowance for the term of your contract. If you exceed your
              allowance, we'll ask you to pay an excess mileage fee when you
              return the vehicle.
            </p>
            <p>
              You can find out how much this might be in your agreement, under{" "}
              <strong>Key information</strong>. It will show your excess mileage
              charge in pence per mile, including VAT.{" "}
            </p>
            <p>
              When you've worked out your excess mileage fee, it's worth getting
              a quote to find out how much it might cost to amend your contract.
              You can then compare the two figures to work out what's cheapest.
            </p>
            <p>
              If you decide to amend your contract, your excess mileage fee may
              change. We'll let you know of any change to your fee before you
              accept your quote.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="20">
          <Accordion.Header>
            I want to return my vehicle before the end of the contract. What do
            I need to do?
          </Accordion.Header>
          <Accordion.Body>
            <p>
              You can book your vehicle return online at{" "}
              <Link to="/">Manage My Vehicle</Link>.
            </p>
            <p>
              If you'd like to return your vehicle before the final month of
              your contract, we class this as an early termination. This will
              involve a cost for ending your contract early, based on how long
              you have left on the contract. You can find out the cost of the
              early termination and accept your quote through{" "}
              <Link to="/">Manage My Vehicle</Link> - where you can also arrange
              your vehicle collection.
            </p>

            <p>
              If you'd like to return your vehicle within the final month of
              your contract, you won't need to pay an early termination charge,
              but you will still need to pay your final month's rental in full.
            </p>

            <p>
              Once you've returned your vehicle, we'll check it over and there
              may be extra costs you will need to pay, like excess mileage and
              damage. If any charges apply, we'll invoice you separately for
              them.
            </p>

            <p>
              You can find out more about excess mileage and damage charges in
              the FAQ section{" "}
              <strong>End of Contract Options and Additional Charges.</strong>
            </p>

            <p className="pt-3 mb-1">
              <strong>If you need more support</strong>
            </p>

            <p>
              If you're thinking of returning a vehicle because you're finding
              it hard to keep up with the payments, we may have other options
              for you. Please call us on{" "}
              <Link to="tel:0800 302 9160">0800 302 9160</Link> to speak to one
              of our advisers. We're here 8am - 5.30pm Monday - Friday.
            </p>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <h3 className="mt-5">Other</h3>
      <Accordion>
        <Accordion.Item eventKey="14">
          <Accordion.Header>How do I make a complaint?</Accordion.Header>
          <Accordion.Body>
            <p>
              We're sorry to hear you're unhappy with something we're doing.
              Please call us on{" "}
              <Link to="tel:0800 068 4025">0800 068 4025</Link> to let us know
              and to make a complaint. For other ways to do so, please go to our
              how to complain page, where you can also find out more about how
              we handle complaints.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="13">
          <Accordion.Header>
            I have a question that isn't covered by the FAQs. What can I do?
          </Accordion.Header>
          <Accordion.Body>
            <p>
              Please go the <Link to="/contact-us">contact us</Link> page, where
              you'll be able to find the team best suited to helping you with
              your query.
            </p>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </TilesPlusMainContentBasePage>
  );
};

export default HelpPage;
